$(document).ready(function() {
    var typed = new Typed("#typed-text p", {
        stringsElement: '#typed-strings',
        typeSpeed: 100,
        backSpeed: 0,
        backDelay: 500,
        startDelay: 1000,
        loop: false,
    });


    $("#head .mobileNav").click(function(){
        if($('#head .nav').hasClass('active')){
            $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');
            $('#head .nav').removeClass('active');
            $('body').removeClass('locked');
        }else{
            $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');
            $('#head .nav').addClass('active');
            $('body').addClass('locked');
        }
    });


    if($( window ).width() > 528){
        str = '<video playsinline webkit-playsinline autoplay muted loop><source src="'+$('#video').attr('data-src')+'" type="video/mp4"><source src="'+$('#video').attr('data-src-webm')+'" type="video/webm"></video>';
        $('#video').html(str);
    }else{
        str = '<video playsinline webkit-playsinline autoplay muted loop><source src="'+$('#video').attr('data-src-mobile')+'" type="video/mp4"><source src="'+$('#video').attr('data-src-mobile-webm')+'" type="video/webm"></video>';
        $('#video').html(str);
    }


    $("#modal").click(function(e) {
        if ($("body").hasClass("modal-active") && (e.target.id != "modal-content") ){
            var p = e.target;
            bool = false;
            while(p) {
                if(p.id) {
                    if(p.id == 'modal-close') {
                        bool = true;
                    }
                }
                p = p.children;
            }
            if(bool){
                $('ul.nav::before').hide();
                $('#head .menu li').removeClass('active');
                $('#modal .content').removeClass('loaded loading');
                $('#modal').addClass('closed').removeClass('open');
                $('#modal .content .content').html('');
                $('body').removeClass('modal-active');
            }
        }
    });

});


function initMap() {
    l =  50.0943472;
    n =  14.4463611;
    // Styles a map in night mode.
    var map = new google.maps.Map(document.getElementById('googleMap'), {
        center: {
            lat: l,
            lng: n
        },
        zoom: 14,
        disableDefaultUI: false,
        scrollwheel: false,
        styles: [{

                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#addbfd"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [{
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [{
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ]
    });
    var image = url+'/img/mapPin.svg';
    var myLatlng = new google.maps.LatLng(50.0943472, 14.4463611);
    var marker = new google.maps.Marker({
        position: myLatlng,
        animation: google.maps.Animation.DROP,
        title: "Hello World!",
        size: new google.maps.Size(71, 74),
        anchor: new google.maps.Point(0, 74),
        icon: image
    });
    marker.setMap(map);
}
